import React, { useRef } from "react";
import "./CryptoTable.css";
import "./Crypto.css";
import { porttcfolio, updatePorttcfolio } from "./data/CryptoData";
import CryptoHelp from "./CryptoHelp";
import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import BarChartIcon from '@mui/icons-material/BarChart';
import { cleanJsonAndStringify } from "./data/CryptoProcessData";
import CryptoHeaderGraph from "./graph/CryptoHeaderGraph";
import { DISPLAY_MODE_ARRAY, DISPLAY_MODE_GRAPH } from "./data/CryptoConfig";
import SearchIcon from '@mui/icons-material/Search';
import FieldEditor from "./array/FieldEditor";

export type MaFonction = (param: any) => void;
export type MaFonction2 = () => void;


const CryptoHeader = (props: any) => {
  const ref = useRef();
  const toggleHelp = () => {
    if(ref.current!=null){
    (ref.current as any).toggleMe();
    }
  }

  const toggleEditMode:any = () => {
    hideBubbles();
    let toto = document.getElementById("App-header");
    if(toto!=null){
      toto.classList.toggle("viewMode");
    }
    props.toggleViewMode();
  }

  const toggleDisplayMode:any = () => {
    props.toggleDisplayMode();
  }

  const updatePorttcfolioInvestment= () =>{
   // porttcfolio.investment=25;
  }

  const showBubbles = () =>{
    document.getElementById("bubbles").classList.remove("hide");
  }
  const hideBubbles = () =>{
    document.getElementById("bubbles").classList.add("hide");
  }

  const saveData = () => {
    let textarea = document.getElementById("dataEditor") as HTMLTextAreaElement;
    localStorage.setItem("cryptool", textarea.value);
    updatePorttcfolio(textarea.value);
    if(document.getElementById("dataEditorPanel")!=null){
      document.getElementById("dataEditorPanel").classList.remove("show");
    }
    props.fullRefresh();
  }

  let total=0;  
  for (let i = 0; i < props.cryptoRows.items.length; i++) {
    let item = props.cryptoRows.items[i];
    let price = item.totalPrice;
    if (price != null) {
      total += price;
    }
  }
  let investment=0;
  if (porttcfolio.investment){
    investment=porttcfolio.investment;
  }
  let impots= (total-investment) * 33 / 100;
  if(impots<0){
    impots=0;
  }
  let gains= total - investment - impots;
  
  if(gains<0){
gains=0;
  }
  let net= total - impots;

  return (
    <div className="crypto-header">
      <div className="crypto-header-buttons">
        <span className="crypto-title">CRYPTOVERVIEW</span>
        <span>

          <Tooltip title="Rafraichir">
            <IconButton onClick={props.fullRefresh}>
              <ReplayCircleFilledIcon />
            </IconButton>
          </Tooltip>

          {props.displayMode===DISPLAY_MODE_ARRAY &&
          <Tooltip title="Basculer en Mode Graphiques">
          <IconButton onClick={toggleDisplayMode}>
            <BarChartIcon/>
            </IconButton>
            </Tooltip>}

          {props.displayMode===DISPLAY_MODE_GRAPH &&
          <Tooltip title="Basculer en Mode Tableau">
          <IconButton onClick={toggleDisplayMode}>
            <BallotIcon/>
            </IconButton>
            </Tooltip>}

            <Tooltip title="Afficher les bulles">
          <IconButton onClick={showBubbles}>
            <BubbleChartOutlinedIcon/>
            </IconButton>
          </Tooltip>


          {props.displayMode===DISPLAY_MODE_ARRAY && <button className="ttcButton" onClick={toggleEditMode}>{props.viewMode ? "Editer tableau" : "Fin édition"}</button>
}

          
          <Tooltip title="Uploader portefeuille">
            <IconButton onClick={displayData}>
              <FileUploadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Downloader portefeuille">
            <IconButton onClick={downloadData}>
              <DownloadForOfflineIcon />
            </IconButton>
          </Tooltip>


          <Tooltip title="Aide">
            <IconButton onClick={toggleHelp}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </span>
        {props.displayMode===DISPLAY_MODE_ARRAY && <span className="crypto-header-search">
        <Tooltip title="Rechercher une crypto-monnaie">
            <SearchIcon/>
          </Tooltip>:&nbsp;
          <input
            type="text"
            onChange={props.handleChange}
            placeholder="Exemple: Bitcoin ou BTC"
          />
        </span>}
        <div className="crypto-header-edit-data" id="dataEditorPanel">
          <br/>
          <textarea id="dataEditor" cols={100} rows={20}></textarea>
          <br />


          <button onClick={openCheckData} className="ttcButton">Vérifier json</button>

         <Tooltip title="Aide">
            <IconButton onClick={toggleHelp}>
              <HelpIcon />
            </IconButton>
          </Tooltip>


          <Tooltip title="Enregistrer">
            <IconButton onClick={saveData}>
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Annuler">
            <IconButton onClick={cancelData}>
              <Cancel />
            </IconButton>
          </Tooltip>

        </div>
      </div>
      <div className="crypto-header-sub">        
      <span className="crypto-summary left">
        <br />ACTUEL:
        <br /><table>
          <tbody>
          <tr><td>Total:</td><td>{total.toFixed(0)} USDT</td></tr>
          <tr><td>Investi:</td><td><FieldEditor
          className="fixedWidth"
          tooltip="Montant investi"
          val={porttcfolio} prop="investment" label=""
          onChange={updatePorttcfolioInvestment}
          viewMode={props.viewMode}
          type="number" key={"key10" + Math.random()} /> USDT</td></tr>
          <tr><td>Impots:</td><td>{impots.toFixed(0)} USDT</td></tr>
          <tr><td>Gains:</td><td>{gains.toFixed(0)} USDT</td></tr>
          <tr><td>Net:</td><td>{net.toFixed(0)} USDT</td></tr>
          </tbody>
        </table>
      </span> 
      <CryptoHeaderGraph cryptoRows={props.cryptoRows} investment={porttcfolio.investment} />
        </div>
      <CryptoHelp open={false} ref={ref} />
    </div>
  );
};

const displayData = () => {
  if(document.getElementById("dataEditorPanel")!=null){
    document.getElementById("dataEditorPanel").classList.add("show");
  }
  let textarea = document.getElementById("dataEditor") as HTMLTextAreaElement;
  textarea.value = cleanJsonAndStringify(porttcfolio);

}

const downloadData = () => {

  let value = cleanJsonAndStringify(porttcfolio);
  const blob = new Blob([value], { type: 'application/json' });

  const a = document.createElement('a');
  a.download = 'portefeuille.json';
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();

}


const cancelData = () => {
  if( document.getElementById("dataEditorPanel")!=null){
  document.getElementById("dataEditorPanel").classList.remove("show");
  }
}


const openCheckData = () => {
  window.open("https://jsonlint.com/");
}



export default CryptoHeader;