import { useState } from "react";
import { getPositionInPorttcfolio, porttcfolio, updatePorttcfolio } from "../data/CryptoData";
import { reprocessTargetsData } from "../data/CryptoProcessData";
import { Tooltip } from "@mui/material";

const FieldEditor = (val: any) => {
    let [value] = useState(val);

    const blurMe = (newValue: any) => {
        if (value.prop === "investment") {
            porttcfolio.investment = newValue;
            let strPorttcfolio = JSON.stringify(porttcfolio);
            updatePorttcfolio(strPorttcfolio);
            localStorage.setItem("cryptool", strPorttcfolio);
        } else {
            value.val[value.prop] = newValue;
            reprocessTargetsData(value.val);
            let pos = getPositionInPorttcfolio(value.val.ttcId, porttcfolio);
            porttcfolio.items[pos] = value.val;
            let strPorttcfolio = JSON.stringify(porttcfolio);
            updatePorttcfolio(strPorttcfolio);
            localStorage.setItem("cryptool", strPorttcfolio);
        }
    }

    let myClassName = "fieldEditor " + val.type;

    if (value.viewMode) {
        return <Tooltip title={val.tooltip}><span className={"fieldEditor viewMode " + val.className}>{val.label ? val.label : ""}{val.type === "currency" && value.val[value.prop] ? "$ " : ""}{value.val[value.prop]}</span></Tooltip>
    } else {
        let valueVal = 0;
        if (value.prop === "investment") {
            valueVal = porttcfolio.investment;
        } else {
            valueVal = value.val[value.prop];
        }

        return (<Tooltip title={val.tooltip}>
            <span className={"fieldEditor editMode " + val.className}>{val.label ? val.label : ""}{val.type === "currency" ? "$ " : ""}<input type="text" defaultValue={valueVal}
                onBlur={e => blurMe(e.target.value)} className={myClassName} /></span></Tooltip>);
    }


}



export default FieldEditor;
