import { calculPrice } from "../array/CryptoRow";
import { getCryptoExpiration } from "./CryptoCache";
import { CryptoTableRow, Porttcfolio, StatusEnum, TargetData, generateTtcId, mapCoinGeckoId } from "./CryptoDataType";
import { loadPrices } from "./CryptoQuery";

export const sortPorttcfolio = (obj: any) => {
  obj.sort((a: any, b: any) => (a.symbol > b.symbol) ? 1 : ((b.symbol > a.symbol) ? -1 : 0))
}

export const cleanJsonAndStringify = (jsonObject: any) => {
  var copiedJson = JSON.parse(JSON.stringify(jsonObject));
  cleanJsonAttribute(copiedJson.items, 'market_cap');
  cleanJsonAttribute(copiedJson.items, 'market_cap_rank');
  cleanJsonAttribute(copiedJson.items, 'current_price');
  cleanJsonAttribute(copiedJson.items, 'ath');
  return JSON.stringify(copiedJson);
}

const cleanJsonAttribute = (copiedJson: any, attrName: string) => {
  for (let i = 0; i < copiedJson.length; i++) {
    delete copiedJson[i][attrName];
  }
}

export const initPorttcfolio = (porttcfolio: Porttcfolio) => {
  loadPrices(porttcfolio);
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (pf.ttcId == null || pf.ttcId === undefined) {
      pf.ttcId = generateTtcId();
    }
    for (let j = 0; j < pf.targets.length; j++) {
      if (pf.targets[j].status == null || pf.targets[j].status === undefined) {
        pf.targets[j].status = StatusEnum.Init;
      }
    }


  }
  sortPorttcfolio(porttcfolio.items);

}

export const filterPorttcfolio = (porttcfolio: Porttcfolio, src: any) => {

  let target: any[];
  target = [];
  let filters = porttcfolio.items;
  if(src.length===0){
    return filters;
  }
  for (let i = 0; i < filters.length; i++) {
    let filter = { ...filters[i] };
    let trouve = false;
    for (let j = 0; j < src.length; j++) {
      let srcData = src[j];

      if (srcData.symbol.toLowerCase() === filter.symbol.toLowerCase()
      ) {
        let newData: CryptoTableRow =
        {
          ttcId: (filter.ttcId != null && filter.ttcId !== undefined) ? filter.ttcId : generateTtcId(),
          symbol: srcData.symbol,
          name: srcData.name,
          image: srcData.image?srcData.image:filter.image,
          site: filter.site,
          bag: filter.bag,
          current_price: srcData.current_price ? srcData.current_price : 0,
          price_change_percentage_24h: srcData.price_change_percentage_24h,
          ath: srcData.ath,
          market_cap: srcData.market_cap,
          market_cap_rank: srcData.market_cap_rank,
          history: filter.history,
          totalPrice: calculPrice(filter.bag, srcData.current_price),
          targets: processTargetData(srcData, filter),
          comment: filter.comment,
          invested: filter.invested,
          coingeckoId: mapCoinGeckoId((filter.coingeckoId == null || filter.coingeckoId === undefined || filter.coingeckoId === '') ? srcData.coingeckoId : filter.coingeckoId),
          loaded:false
        }
        target.push(newData);
        trouve = true;
        break;
      }
    }
    let cryptoPrice=getCryptoExpiration(filter.symbol,filter.name===undefined?'':filter.name);
    if(!trouve){
      let cryptoPrice=getCryptoExpiration(filter.symbol,filter.name===undefined?'':filter.name);

      if(cryptoPrice !== null){
        let newData: CryptoTableRow =
        {
          ttcId: (filter.ttcId != null && filter.ttcId !== undefined) ? filter.ttcId : generateTtcId(),
          symbol: filter.symbol,
          name: filter.name,
          image: filter.image?filter.image:cryptoPrice.image,
          site: filter.site,
          bag: filter.bag,
          current_price: cryptoPrice.value,
          price_change_percentage_24h: (cryptoPrice.price_change_percentage_24h==null?0:cryptoPrice.price_change_percentage_24h),
          market_cap: filter.market_cap,
          market_cap_rank: filter.market_cap_rank,
          ath: filter.ath,
          history: filter.history,
          totalPrice: calculPrice(filter.bag, cryptoPrice.value),
          targets: processTargetData(filter, filter),
          comment: filter.comment,
          invested: filter.invested,
          coingeckoId: mapCoinGeckoId((filter.coingeckoId == null || filter.coingeckoId === undefined || filter.coingeckoId === '') ? cryptoPrice.coingeckoId : filter.coingeckoId),
          loaded:false
        }
        target.push(newData);
        trouve=true;
      }
    }

    if (!trouve) {
      let currentPrice=0;
      if(filter.current_price!==undefined){
        currentPrice=filter.current_price;
      }
      let coingeckoId=null;
      if(filter.coingeckoId!==undefined && filter.coingeckoId!=null){
        coingeckoId=filter.coingeckoId;
      }
      let newData: CryptoTableRow =
      {
        ttcId: (filter.ttcId != null && filter.ttcId !== undefined) ? filter.ttcId : generateTtcId(),
        symbol: filter.symbol,
        name: filter.name,
        image: filter.image?filter.image:(cryptoPrice.image?cryptoPrice.image:''),
        site: filter.site,
        bag: filter.bag,
        current_price: currentPrice,
        price_change_percentage_24h:  (cryptoPrice==null?0:cryptoPrice.price_change_percentage_24h==null?0:cryptoPrice.price_change_percentage_24h),
        market_cap: 0,
        market_cap_rank: 0,
        ath: 0,
        history: '',
        totalPrice: calculPrice(filter.bag, currentPrice),
        targets: processTargetData(filter, filter),
        comment: filter.comment,
        invested: filter.invested,
        coingeckoId: mapCoinGeckoId(coingeckoId),
        loaded:false
      }
      target.push(newData);
    }
  }
  return target;
}


export const processTargetData = (srcData: any, filter: any) => {
  let result: TargetData[] = [];
  let targetData: TargetData[] = filter.targets;

  if (targetData) {
    for (let i = 0; i < targetData.length; i++) {
      let src: TargetData = targetData[i];
      let rate: number | null = (src.rate === undefined ? null : src.rate);
      if (src.bagPerc === undefined || src.bagPerc === null) {
        src.bagPerc = 20;
      } else if (typeof src.bagPerc === "string") {
        src.bagPerc = parseInt(src.bagPerc);
      }

      if (src.notificationHigh0 === undefined || src.notificationHigh0 === null) {
        src.notificationHigh0 = 60;
      } else if (typeof src.notificationHigh0 === "string") {
        src.notificationHigh0 = parseInt(src.notificationHigh0);
      }
      if (src.notificationHigh1 === undefined || src.notificationHigh1 === null) {
        src.notificationHigh1 = 70;
      } else if (typeof src.notificationHigh1 === "string") {
        src.notificationHigh1 = parseInt(src.notificationHigh1);
      }
      if (src.notificationHigh2 === undefined || src.notificationHigh2 === null) {
        src.notificationHigh2 = 80;
      } else if (typeof src.notificationHigh2 === "string") {
        src.notificationHigh2 = parseInt(src.notificationHigh2);
      }


      
      if (typeof src.rate === "string") {
        src.rate = parseFloat(src.rate);
      }

      let bagOfTarget = filter.bag * src.bagPerc / 100;
      result.push({
        rate: src.rate,
        perc: src.rate == null ? null : (srcData.current_price * 100 / src.rate),
        price: calculPrice(bagOfTarget, rate),
        bag: bagOfTarget,
        bagPerc: src.bagPerc,
        sold: src.sold,
        status: src.status,
        graphProcessed: false,
        notificationHigh0:src.notificationHigh0,
        notificationHigh1:src.notificationHigh1,
        notificationHigh2:src.notificationHigh2
      });
    }
  }

  return result;
}

export const reprocessTargetsData = (row: CryptoTableRow) => {
  if (row.targets) {
    for (let i = 0; i < row.targets.length; i++) {
      let target = row.targets[i];
      let rate: number | null = (target.rate === undefined ? null : target.rate);
      let current_price = (row.current_price == null || row.current_price === undefined) ? 0 : row.current_price;

      if (target.bagPerc === undefined || target.bagPerc === null) {
        target.bagPerc = 20;
      }
      if (target.notificationHigh0 === undefined || target.notificationHigh0 === null) {
        target.notificationHigh0 = 60;
      }
      if (target.notificationHigh1 === undefined || target.notificationHigh1 === null) {
        target.notificationHigh1 = 70;
      }
      if (target.notificationHigh2 === undefined || target.notificationHigh2 === null) {
        target.notificationHigh2 = 80;
      }


  

      let bagOfTarget = row.bag * target.bagPerc / 100;


      target.bag = bagOfTarget;
      target.price = calculPrice(bagOfTarget, rate);
      target.perc = (rate == null || current_price === undefined) ? null : (current_price * 100 / rate);
    }

  }
}

