import { TableCell, Tooltip } from "@mui/material";
import { calculPrice } from "./CryptoRow";
import { toFixed } from "../data/CryptoData";

interface IProps {
    row: any;
  }

const CryptoRowTargetSummary: React.FC<IProps> = ({  row }) => {

    let totalPerc=0;
    let totalAmount=0;
    for (let i = 0; i < row.targets.length; i++) {
        let rate = (row.targets[i].rate == null || row.targets[i].rate === undefined ? null : row.targets[i].rate);
        let targetPrice = calculPrice(row.targets[i].bag, rate);
        totalAmount+=targetPrice;
        totalPerc+=row.targets[i].bagPerc;
    }
    console.log(row);

    return <TableCell  colSpan={2} align="right" className={"rowTarget summary"}>
        <div>Résumé</div>
        <Tooltip title="Cours actuel"><div>${row.current_price && toFixed(row.current_price,6)}</div></Tooltip>
        <Tooltip title="Total pourcentage objectif défini"><div className={totalPerc<100?"summaryPercNotFull":""}>{totalPerc}%</div></Tooltip>
        <Tooltip title="Total montant objectif défini"><div>${toFixed(totalAmount,0)}</div></Tooltip>
        </TableCell>;
    
}

export default CryptoRowTargetSummary;