//needed for json parse/jsonify - do not remove
Date.prototype.toJSON = function () {
    return this.getTime().toString();
   }

export const getCryptoValue = (symbol:string,name:string) => {
   // let expirationStorage:any=null;
    let expirationStorage:any=localStorage.getItem("cryptoolExpiration");
    if(expirationStorage!=null && expirationStorage !== undefined){
        expirationStorage=JSON.parse(expirationStorage);
        let cryptoExpiration=expirationStorage[symbol.toUpperCase()+"-"+name.toUpperCase()];
        if(cryptoExpiration==null || cryptoExpiration===undefined){
            return null;
        }else{
            let expiration:Date= cryptoExpiration.expiration;
            if(cryptoExpiration && ( (new Date())<expiration)){
                return cryptoExpiration.value;
            }else{
                return null;
            }
        }
    }else{
        return null;
    }
}

export const getCryptoExpiration = (symbol:string,name:string) => {
   // let expirationStorage:any=null;
   let expirationStorage:any=localStorage.getItem("cryptoolExpiration");
    if(expirationStorage!=null && expirationStorage !== undefined){
        expirationStorage=JSON.parse(expirationStorage);
        let cryptoExpiration=expirationStorage[symbol.toUpperCase()+"-"+name.toUpperCase()];
        if(cryptoExpiration==null || cryptoExpiration===undefined){
            return null;
        }else{
            let expiration:Date= cryptoExpiration.expiration;
            if(cryptoExpiration && ( (new Date())<expiration)){
                return cryptoExpiration;
            }else{
                return null;
            }
        }
    }else{
        return null;
    }
}

export const setCrypto = (symbol:string,name:string,value:any,coingeckoId:string,history:string|undefined,price_change_percentage_24h:number|null) => {
    let expirationStorage:any=localStorage.getItem("cryptoolExpiration");
    if(expirationStorage==null || expirationStorage === undefined){
        expirationStorage= {};
    }else{
        expirationStorage = JSON.parse(expirationStorage);
    }
    let expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + 10);
    expirationStorage[symbol.toUpperCase()+"-"+name.toUpperCase()]= 
    {'value':value,'expiration':expirationDate,'coingeckoId':coingeckoId,'history':history
,'price_change_percentage_24h':(price_change_percentage_24h===null?expirationStorage.price_change_percentage_24h:price_change_percentage_24h)};
    localStorage.setItem("cryptoolExpiration",JSON.stringify(expirationStorage));
}