import { Porttcfolio } from "./CryptoDataType";

export const updatePorttcfolio = (value: string) => {

  try {
    portfolio = JSON.parse(value);
  } catch (e) {
    alert("Mauvais ancien format");
  }

  try {
    if (value.indexOf("investment") >= 0) {
      porttcfolio = JSON.parse(value);
    } else {
      porttcfolio = {
        investment: 0,
        items: JSON.parse(value)
      };
    }
  } catch (e) {
    alert("Mauvais nouveau format");

    porttcfolio = {
      investment: 0,
      items: []
    };
  }

}

//todo cst old format
export let portfolio = [
  { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "USDT", "site": "bybit", "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }] },
  { "symbol": "TAO", "site": "blofin", "bag": 5, "invested": "200", "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu" },
  { "symbol": "BTC", "site": "bybit", "bag": 5, "targets": [{ "rate": 70000 }] },
  { "symbol": "ETH", "site": "ledger", "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }] },
  { "symbol": "XRP", "site": "metamask", "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }] },
  { "symbol": "AKT", "site": "blofin", "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }] }
];

export let porttcfolio: Porttcfolio =
{
  investment: 0,
  items: [
    { "ttcId": "ttc1", "symbol": "USDT", "site": "blofin", "bag": 5000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false },
    { "symbol": "USDT", "site": "bybit", "bag": 1000, "targets": [{ "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }, { "rate": 1 }], loaded: false },
    { "symbol": "TAO", "site": "blofin", "bag": 5, "invested": 200, "targets": [{ "rate": 700 }], "comment": "nouvel ATH attendu", loaded: false },
    { "symbol": "BTC", "site": "bybit", "bag": 5, "targets": [{ "rate": 70000 }], loaded: false },
    { "symbol": "ETH", "site": "ledger", "bag": 5, "targets": [{ "rate": 4000 }, { "rate": 5000 }, { "rate": 6000 }, { "rate": 7000 }, { "rate": 8000 }], loaded: false },
    { "symbol": "XRP", "site": "metamask", "bag": 5, "targets": [{ "rate": 1, "sold": true }, { "rate": 2 }, { "rate": 3 }], loaded: false },
    { "symbol": "AKT", "site": "blofin", "bag": 5, "targets": [{ "rate": 7 }, { "rate": 8 }], loaded: false }
  ]
};

export const getPositionInPorttcfolio = (ttcId: string, porttcfolio: Porttcfolio) => {
  return getPositionInSortedRows(ttcId, porttcfolio.items);
}

export const getPositionInSortedRows = (ttcId: string, sortedRows: any) => {
  for (let i = 0; i < sortedRows.length; i++) {
    if (sortedRows[i].ttcId === ttcId) {
      return i;
    }
  }
  return -1;
}

export const toFixed = (value: any, precision:number) =>{
  if(value==null || value === undefined){
    return null;
  }else if (isNaN(value)){
    return Number(value).toFixed(precision);
  }else{
    let valueNumber: Number=value;
    return valueNumber.toFixed(precision);
  }
}


